import React from "react"
import LayoutDay from "../../../components/Chronology/LayoutDay"
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft"
import ContentRight from "../../../components/Chronology/Modules/ContentRight"
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable"
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu"
import ModDottedLine from "../../../components/Chronology/Modules/ModDottedLine"
import ModLethalityEu from "../../../components/Chronology/Modules/ModLethalityEu"
import ModText from "../../../components/Chronology/Modules/ModText"

export const frontmatter = {
  title: "Día 106",
  week: "Semana 16",
  day: "27",
  month: "Jun",
  monthNumber: "06",
  date: "2020-06-27",
  path: "/cronologia/semana-16#dia-27-jun/",
}

const Day106 = props => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModDataEu fecha={frontmatter.date} />
        <ModLethalityEu fecha={frontmatter.date} numPaises={8} />
      </ContentLeft>

      <ContentRight>
        <ModText>
          En los últimos siete días se han diagnosticado en <strong>España</strong> 1.862 casos
          mediante pruebas PCR. En ese mismo periodo, 150 personas han requerido hospitalización
          (8,0% de los diagnósticos), de los que 17 han sido ingresos en la UCI, y se han producido
          13 fallecimientos.
        </ModText>
        <ModDottedLine />
        <ModCCAATable fecha={frontmatter.date} />
      </ContentRight>
    </LayoutDay>
  )
}
export default Day106
